import { useForm } from "@inertiajs/inertia-react";
import axios from "axios";
import { useDebouncedCallback } from "components/Hooks/useDebouncedCallback";
import { useEffect, useState } from "react";
import Button from "./Button";
import Input from "./Input";
import { useDebounce } from 'use-debounce';
import LoadingSpinner from "components/Icons/LoadingSpinner";
import ValidateOk from "components/Icons/ValidateOk";
import ValidateError from "components/Icons/ValidateError";

export default function PromoCode(props) {

    const { setNewValues, setType, setVariableInterest, setVoucherCode = () => {} } = props

    const [clicked, setClicked] = useState(false);
    const [loading, setLoading] = useState(false)
    const [successCode, setSuccessCode] = useState(null as any)
    const [status, setStatus] = useState(null as any);
    const initialInterest = [0.1, 0.5]

    const form = useForm({
        promo_code: ""
    });

    const { data, setData, errors, clearErrors, post } = form;

    const [debouncedValue] = useDebounce(data.promo_code, 2000)

    async function check() {

        axios.post('/api/check-promo-code', { code: debouncedValue }).then(res => {
            console.log(res?.data)
            setLoading(false)
            if (res?.data.percentages) {
                if (res?.data?.type == "fixed") {
                    setType("fixed")
                    setNewValues(res.data.percentages)
                }
                else if (res.data.type == "variable") {
                    setType("variable")
                    setVariableInterest(Number(res.data.percentages))
                }
                setStatus("success")
                setVoucherCode(debouncedValue)
            }
            else {
                setStatus("error")
                setVoucherCode(null)
            }
           
        })


    }

    useEffect(() => {
        console.log(debouncedValue)
        if (debouncedValue != "") {
            check()
        }

    }, [debouncedValue]);

    useEffect(() => {
        if (data.promo_code != "") {
            setLoading(true)
        }
        else {
            setLoading(false)
        }
    }, [data.promo_code]);

    function undo() {
        setClicked(false)
        setStatus(null)
        setNewValues(
            [
                "0.5", "0.4", "0.3", "0.25"
            ]
        )
        setVariableInterest(0)
        setVoucherCode(null)
    }

    return (
        <div className="">
            {!clicked ?
                <div className="text-xs underline cursor-pointer" onClick={() => setClicked(true)}>Vložit promo kód</div>
                :
                <div className="flex relative">
                    {status == "success" ?
                        <div className="block">
                            <div className="h-12 flex items-center">Použitý promokód: &nbsp;&nbsp;<span className="font-semibold uppercase">{data.promo_code}</span>

                            </div>
                            <div onClick={() => undo()} className="text-sm underline cursor-pointer">
                                Zrušit použití kódu
                            </div>
                        </div>
                        :

                        <Input error={status == "error" ? "Zadaný kód není platný" : null} name="promo_code" label="Promo kód" className={
                            "" +
                            ((status == "success") && " border-green-900") +
                            ((status == "error") && " border-red-500")
                        } values={data.promo_code} setValues={setData} />
                    }
                    <div className="w-12 absolute right-2 top-4 ml-2 flex items-center justify-center">
                        {loading ?
                            <LoadingSpinner width={48} />
                            :
                            (status == "success" && data.promo_code != "") ? <ValidateOk /> :
                                (status == "error" && data.promo_code != "") ? <ValidateError /> :
                                    <div className=""></div>
                        }
                    </div>
                </div>
            }

        </div>
    )
}