import React, { useEffect, useRef, useState } from "react";

import AppLayout from "components/Layouts/AppLayout";
import Bloomberg from "fragments/Home/Bloomberg";
import FreerWorld from "fragments/Home/FreerWorld";
import Guide from "fragments/Home/Guide";
import Hero from "fragments/Home/Hero";
import Layout from "components/Layouts/Layout";
import NewEraOfTheInternet from "fragments/Home/NewEraOfTheInternet";
import Offering from "sharedFragments/Offering";
import PathToTheStars from "fragments/Home/PathToTheStars";
import Products from "fragments/Home/Products";
import SpektrumLogo from "components/Logos/SpektrumLogo";
import TechnologicRevolution from "fragments/Home/TechnologicRevolution";
import ProgressLoading from "fragments/Home/ProgressLoading";
import PhoneModal from "components/PhoneModal";
import ReactPageScroller from "react-page-scroller";
import Navbar from "components/Navbar";
import ContactUs from "sharedFragments/ContactUs";
import FAQ from "sharedFragments/FAQ";
import Footer from "components/Layouts/Footer";
import TechnologicRevolutionNew from "fragments/Home/TechnologicRevolutionNew";
import ScrollDownButton from "components/ScrollDownButton";
import { AnimatePresence, motion } from "framer-motion";
import ExponencialGrow from "fragments/Home/ExponencialGrow";
import { useDebouncedCallback } from "components/Hooks/useDebouncedCallback";
import Secure from "fragments/Home/Secure";
import BookMeeting from "fragments/Home/BookMeeting";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { getScrollPosition } from "components/Hooks/useScroll";
import { useScrollYPosition } from 'react-use-scroll-position';
import Calculator from "fragments/Home/Calculator";
import ContactAndFaq from "sharedFragments/ContactAndFaq";
import TechnologicRevolutionPhone from "fragments/Home/TechnologicRevolutionPhone";
import { isMobile } from "react-device-detect";
import Head from "components/Head";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

export default function Home() {

    // const container = useRef(null as any)
    // useScrollSnap({ ref: container, duration: 250, delay: 0 });
    const scrollY = useScrollYPosition();

    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(0);
    const [down, setDown] = useState(false);

    function changePage(page: number, force?: boolean) {
        if (scrollY < 100 && (!down || force)) {
            if (page >= 0 && page <= 8) {
                setPage(page)
            }
        }

    }

    useEffect(() => {
        console.log(down)
    }, [down]);

    useEffect(() => {
        // if (scrollY > 100) {
        //     setPage(6)
        // }
    }, [scrollY])

    useEffect(() => {
        const style = document.createElement("style");
        if (!isMobile) {
            document.head.appendChild(style);
            style.innerHTML = `@media screen and (min-width: 1024px) {
    
                html, body {
                    height: 100%;
                    overflow: hidden;
                }
                }}`;
        }
        return () => {
            var hs = document.getElementsByTagName('style');
            console.log(hs)
            console.log(hs[hs.length - 1])
            document.head.removeChild(hs[hs.length - 1]);

        }
    }, []);


    return (
        <ReactScrollWheelHandler
            upHandler={(e) => changePage(nextPage - 1)}
            downHandler={(e) => changePage(nextPage + 1)}
            wheelConfig={[1, 90, 1, 1]}
            onPointerDown={() => setDown(true)}
            onPointerUp={() => {
                setTimeout(() => {
                    setDown(false)
                }, 100)

            }}
        >
            <AppLayout>
                <Layout>


                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: (nextPage == 4) ? 1 : 0 }}
                        exit={{ opacity: 0, transition: { duration: 0.25 } }}
                        transition={{ duration: 0.5 }}
                        className="fixed top-0 w-full h-full overflow-hidden left-0 right-0 bottom-0 pointer-events-none"
                    >

                        <img className="object-cover overflow-hidden fixed top-0 left-0 right-0 bottom-0" src="/assets/img/earth.jpg" />


                    </motion.div>

                    <Navbar changePage={(to: number) => changePage(to, true)} home />
                    <PhoneModal />
                    {(!isMobile && nextPage <= 7) &&
                        <ScrollDownButton
                            nextSlide={() => changePage(nextPage + 1, true)}
                            previousSlide={() => changePage(nextPage - 1, true)}
                            firstSlide={nextPage == 0}
                            lastSlide={nextPage == 7}
                        />
                    }

                    <div className="hidden lg:block">
                        <ReactPageScroller blockScrollDown={nextPage < 5} blockScrollUp={nextPage < 5} onBeforePageScroll={(page) => setNextPage(page)} pageOnChange={(page) => setPage(page)} customPageNumber={page} animationTimer={500} animationTimerBuffer={10}>
                            <div className="flex h-full items-center  ">
                                <Hero goToPage={(page: number) => changePage(page, true)} />
                            </div>
                            <ExponencialGrow show={(page == 1 || nextPage == 1)} />
                            <Secure show={(page == 2 || nextPage == 2)} />
                            <TechnologicRevolutionNew show={(page == 3 || nextPage == 3)} />
                            <BookMeeting show={(page == 4 || nextPage == 4)} changePage={() => changePage(6, true)} />
                            {/* <FreerWorld />
                    <PathToTheStars />
                    <Guide /> */}
                            <Calculator show={(page == 5 || nextPage == 5)} />
                            {/* <Bloomberg /> */}
                            <ContactAndFaq />
                            <Footer />

                        </ReactPageScroller>
                    </div>


                    {/* Mobil */}

                    <div className="block lg:hidden overflow-x-hidden">

                        <div className="flex h-full items-center  ">
                            <Hero goToPage={(page: number) => changePage(page, true)} />
                        </div>
                        <ExponencialGrow show={(page == 1 || nextPage == 1)} />
                        <Secure show={(page == 2 || nextPage == 2)} />
                        <TechnologicRevolutionPhone show={(page == 3 || nextPage == 3)} />
                        <BookMeeting show={(page == 4 || nextPage == 4)} changePage={() => { }} />
                        {/* <FreerWorld />
                    <PathToTheStars />
                    <Guide /> */}
                        <Calculator show={(page == 5 || nextPage == 5)} />
                        {/* <Bloomberg /> */}
                        <ContactAndFaq />
                        <Footer />
                    </div>
                </Layout>
            </AppLayout>
        </ReactScrollWheelHandler>

    );
}

function PageComponent({ children }) {
    return (<div>{children}</div>)
}