import React from "react";

import AppLayout from "components/Layouts/AppLayout";
import LoggedUser from "components/LoggedUser";
import ProductChoice from "fragments/Profile/ProductChoice";
import SpektrumLogo from "components/Logos/SpektrumLogo";
import PortfolioValue from "fragments/Profile/PortfolioValue";
import SuccessModal from "fragments/Profile/SuccessModal";
import Navbar from "components/Navbar";

export default function Profile(props) {

    const {successModal} = props

    return (
        <AppLayout>
            <SuccessModal opened={successModal} />
            <PortfolioValue />
            {/* <SpektrumLogo />
            <LoggedUser />
            <ProductChoice /> */}
        </AppLayout>
    );
}
