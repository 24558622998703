import React, { useEffect, useState } from 'react';

import AdminLayout from 'components/Layouts/AdminLayout';
import BlogpostsOverview from 'fragments/Admin/BlogpostsOverview';
import axios from 'axios';
import Title from 'components/Typography/Title';
import { InertiaLink, useForm } from '@inertiajs/inertia-react';
import Eye from 'components/Icons/Eye';
import Delete from 'components/Icons/Delete';
import Interest from 'components/Icons/Interest';
import { format } from 'date-fns';
import SimpleDate, { getDate } from 'components/DateFormatter';
import Button from 'components/Forms/Components/Button';
import Input from 'components/Forms/Components/Input';
import { Inertia } from '@inertiajs/inertia';
import { compound, compoundValue } from 'components/Hooks/InterestFunctions/variableInterest';

export default function NewDeposit() {

    const [months, setMonths] = useState(0);

    const form = useForm({
        number: null as any,
        amountBTC: null as any,
        finalBilance: null as any,
        interest: null as any,
        finalInterest: null as any,
        expiration: null as any,
        user_UUID: null as any,
        payment: new Date(),
        paymentId: null as any,
        paymentStatus: null as any,
        type: "fixed",
        interestBonus: 0,
    })
    const { data, setData, errors, clearErrors, post } = form;

    function create() {
        axios.post("/api/deposits/add", data).then(res => {
            console.log(res)
            if (res.status == 200) {
                Inertia.visit("/admin/ulozky")
            }
        })
    }

    function changeTime(num) {
        var d = new Date();
        var newDate = (d.setMonth(d.getMonth() + num));
        console.log(new Date(new Date().getTime() + (2629800000 * num)).toISOString())
        setData({ ...data, expiration: new Date(newDate).toISOString().slice(0, 16) })
        setMonths(num)
    }

    useEffect(() => {
        if (data.type == "fixed") {
            setData({
                ...data,
                finalBilance: data.amountBTC / 100 * (compound(data.interest, months) + 100),
                finalInterest: compound(data.interest, months)
            })
        }

    }, [data.amountBTC, data.interest]);

    return (
        <AdminLayout>
            <div className="min-h-screen px-5 lg:px-32 pt-32">
                <div className="flex justify-between items-baseline">
                    <Title level={2}>Nová úložka</Title>
                    <div className="inline-flex">
                        <Button theme="primary" onClick={() => create()}>Vytvořit</Button>
                    </div>

                </div>

                <div className="mt-4">
                    <form className='grid grid-cols-2 gap-4'>
                        <div className="col-span-2">
                            <input type="radio" onChange={(el) => {
                                setData({
                                    ...data,
                                    type: el.target.value
                                })
                            }} id="fixed" name="type" value="fixed" defaultChecked />
                            <label className='ml-2' htmlFor="fixed">Termínovaná úložka</label><br />
                            <input type="radio" onChange={(el) => {
                                setData({
                                    ...data,
                                    type: el.target.value
                                })
                            }} id="variable" name="type" value="variable" />
                            <label className='ml-2' htmlFor="variable">Úložka s variabilním úrokem</label><br />
                        </div>
                        <Input
                            name="amountBTC"
                            type="number"
                            label="Množství (BTC)"
                            values={data}
                            setValues={setData}
                            error={errors.amountBTC}
                        />
                        <Input
                            name="user_UUID"
                            type="text"
                            label="UUID uživatele"
                            values={data}
                            setValues={setData}
                            error={errors.user_UUID}
                        />
                        {data.type == "variable" &&
                            <>
                                <Input
                                    name="interestBonus"
                                    type="number"
                                    label="Zvýhodění úroku (v %)"
                                    values={data}
                                    setValues={setData}
                                    error={errors.interestBonus}
                                />
                            </>
                        }
                        {data.type == "fixed" &&
                            <>
                                <div className="grid grid-cols-4 col-span-2 gap-x-4">
                                    <Button noSubmit theme="secondary" onClick={() => changeTime(3)}>Expirace 3m od teď</Button>
                                    <Button noSubmit theme="secondary" onClick={() => changeTime(6)}>Expirace 6m od teď</Button>
                                    <Button noSubmit theme="secondary" onClick={() => changeTime(12)}>Expirace 12m od teď</Button>
                                    <Button noSubmit theme="secondary" onClick={() => changeTime(24)}>Expirace 24m od teď</Button>
                                </div>
                                <Input
                                    name="interest"
                                    type="text"
                                    label="Úrok (p. m.)"
                                    values={data}
                                    setValues={setData}
                                    error={errors.interest}
                                />


                                <Input
                                    name="expiration"
                                    type="datetime-local"
                                    label="Expirace"
                                    values={data}
                                    setValues={setData}
                                    error={errors.expiration}
                                />

                            </>
                        }
                        <label htmlFor="myCheck">
                            <input type="checkbox" id="myCheck" onChange={(e) => {
                                setData({
                                    ...data,
                                    paymentStatus: e.target.checked ? "paid" : ""
                                })
                            }} className='mr-2'></input>
                            Označit úložku jako zaplacenou
                        </label>
                        {data.paymentStatus == "paid" &&
                            <Input
                                name="payment"
                                type="datetime-local"
                                label="Datum platby (začátek platnosti úložky)"
                                values={data}
                                setValues={setData}
                                error={errors.expiration}
                            />
                        }
                        <div className="col-span-2">Finální úrok: {compound(data.interest, months)} %</div>
                        <div className="col-span-2">Finální bilance: {data.amountBTC / 100 * (compound(data.interest, months) + 100)} BTC</div>




                    </form>
                </div>
            </div>
        </AdminLayout>
    );
}


function State({ interest, previous }) {

    const now = new Date()

    if (getDate(interest.from) > now) {
        return (
            <div className="bg-blue-400 bg-opacity-50 text-white font-semibold text-sm text-center px-4 rounded-full">Budoucí</div>
        )
    }

    else if ((getDate(interest.from) < now) && (getDate(previous?.from) > now)) {
        return (
            <div className="bg-green text-white font-semibold text-sm rounded-full text-center px-4">Aktivní sazba</div>
        )
    }

    else if (getDate(interest.from) < now) {
        return (
            <div className=" bg-white bg-opacity-30 text-white font-semibold text-sm rounded-full text-center px-4">Minulá sazba</div>
        )
    }

    else return (
        <div className=""></div>
    )
}