import React, { useContext } from "react";

import AppLayout from "components/Layouts/AppLayout";
import LoggedUser from "components/LoggedUser";
import ProductChoice from "fragments/Profile/ProductChoice";
import SpektrumLogo from "components/Logos/SpektrumLogo";
import PortfolioValue from "fragments/Profile/PortfolioValue";
import { AppContext } from "components/Contexts/AppContextProvider";
import usePageProps from "components/Hooks/usePageProps";
import { DataGrid } from '@mui/x-data-grid';
      

export default function Profile() {

    const { user } = usePageProps();

    console.log(user)

    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'number', headerName: 'Číslo úložky', width: 200 },
        { field: 'amountBTC', headerName: 'Množství BTC', width: 200 },
        { field: 'expiration', headerName: 'Expirace', width: 200 },
        { field: 'paymentId', headerName: 'Platební Coinbase code', width: 200 },
        { field: 'payment', headerName: 'Datum platby', width: 200 },
        { field: 'paymentStatus', headerName: 'Stav platby', width: 200 },
    ]

    return (
        <AppLayout>
           
          <div className="bg-white w-full h-screen relative">
               <DataGrid density="compact" columns={columns}  rows={user?.deposits.data} />;
          </div>
           
           
        
        </AppLayout>
    );
}
