import axios from "axios";
import { interestsVariableArray } from "components/StaticData";
import NiceDate from "fragments/NiceDate";
import React, { FC, HTMLProps, useEffect, useState } from "react";

type Props = {
    amountBTC: number;
    expiration: string;
    id: string;
    interest: string;
} & HTMLProps<HTMLDivElement>;

const DepositCard: FC<Props> = (props) => {
    const { amountBTC, expiration, id, interest } = props;

    return (
        <div
            {...props}
            className="p-4 flex justify-between bg-white-10 hover:bg-white-20 rounded-3px tracking-wide cursor-pointer transition-all duration-300 ease-in-out"
        >
            <div className="space-y-1">
                <p className="font-bold">{id}</p>
                {expiration &&
                    <p className="font-thin text-white-60 ">

                        do <NiceDate date={new Date(expiration)} />

                    </p>
                }
                {!expiration &&
                    <p className="font-thin text-white-60 ">

                        variabilní

                    </p>
                }
            </div>
            <div className="space-y-1">
                <p className="font-bold text-right">{amountBTC} BTC</p>
                <p className="font-thin text-white-60 text-right flex items-center justify-end">
                    {interest ?? <ActualInterest />}% p.m.
                </p>
            </div>
        </div>
    );
};

export function ActualInterest() {

    const [value, setValue] = useState(null as any);

    useEffect(() => {
        axios.get("api/interests/actual").then(res => {
            console.log(res?.data?.interests)
            if (res?.data?.interests) {
                setValue(res.data.interests.interest)
            }
        })
    }, []);

    return (
        <span className="">aktuálně {value ?? <div className="w-6 h-4 bg-white-10 rounded"></div>}</span>
    )
}

export function RangeInterest(props) {

    const { bonus } = props

    const [variablePercentage, setVariablePercentage] = useState(interestsVariableArray);

    return (
        <div className="flex">
            <span className={"flex "+((bonus != 0 && bonus > 0) && " line-through text-white-30 font-normal")}>{variablePercentage ? variablePercentage[0] + " - " + variablePercentage[1] + " " : <div className="w-10 h-4 bg-white-10 rounded"></div>}</span>
            {(bonus != 0 && bonus > 0) &&
                <span className="flex ml-2">{variablePercentage ? variablePercentage[0]+Number(bonus) + " - " + (variablePercentage[1]+Number(bonus)) + " " : <div className="w-10 h-4 bg-white-10 rounded"></div>}</span>
            }
        </div>

    )
}

export default DepositCard;
