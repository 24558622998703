import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer"
import { useScrollYPosition } from "react-use-scroll-position"


export default function ProgressContent({ children }) {

    const scrollY = useScrollYPosition()
    const [progress, setProgress] = useState(0);

    const ref = useRef(null as any)

    useEffect(() => {
        if (ref) {
            const progressDoneValue = ref.current.clientHeight
            const currentPercentage = 100 / progressDoneValue * scrollY
            setProgress(currentPercentage)
        }

    }, [scrollY]);


    return (
        <div className="" ref={ref}>
            <motion.div
                animate={{ width: progress + "%" }}
                style={{zIndex: 999, height: 2}}
                initial={false}
                className="fixed top-0 left-0 bg-black "></motion.div>
                 <div
               
                style={{zIndex: 998, height: 1}}
                className="fixed top-0 w-full left-0 right-0 bg-black-20 "></div>
            {children}
        </div>
    )
}