import React, { useEffect, useState } from 'react';

import AdminLayout from 'components/Layouts/AdminLayout';
import BlogpostsOverview from 'fragments/Admin/BlogpostsOverview';
import axios from 'axios';
import Title from 'components/Typography/Title';
import { InertiaLink, useForm } from '@inertiajs/inertia-react';
import Eye from 'components/Icons/Eye';
import Delete from 'components/Icons/Delete';
import Interest from 'components/Icons/Interest';
import { format } from 'date-fns';
import SimpleDate, { getDate } from 'components/DateFormatter';
import Button from 'components/Forms/Components/Button';
import Input from 'components/Forms/Components/Input';
import { Inertia } from '@inertiajs/inertia';
import { el } from 'date-fns/locale';

export default function NewPromoCode() {

    const form = useForm({
        code: null as any,
        percentages: [] as any,
        percentages_3m: null as any,
        percentages_6m: null as any,
        percentages_12m: null as any,
        percentages_24m: null as any,
        percentages_variable: null as any,
        valid_to: null as any,
        use_once: false,
        type: "fixed"
    })
    const { data, setData, errors, clearErrors, post } = form;

    function create() {
        axios.post("/api/codes/add", {
            ...data,
            percentages: !data.percentages[0] ? Number(data.percentages) : data.percentages
        }).then(res => {
            console.log(res)
            if (res.status == 200) {
                Inertia.visit("/admin/promo-kody")
            }
        })
    }

    useEffect(() => {
        setData({
            ...data,
            percentages: [Number(data.percentages_24m), Number(data.percentages_12m), Number(data.percentages_6m), Number(data.percentages_3m)]
        })
    }, [data.percentages_12m, data.percentages_24m, data.percentages_3m, data.percentages_6m]);

    useEffect(() => {
        setData({
            ...data,
            percentages: Number(data.percentages_variable)
        })
    }, [data.percentages_variable]);

    return (
        <AdminLayout>
            <div className="min-h-screen px-5 lg:px-32 pt-32">
                <div className="flex justify-between items-baseline">
                    <Title level={2}>Nový promo kód</Title>
                    <div className="inline-flex">
                        <Button theme="primary" onClick={() => create()}>Vytvořit</Button>
                    </div>

                </div>

                <div className="mt-4">
                    <form className='grid grid-cols-2 gap-4'>
                        <Input
                            name="code"
                            type="text"
                            label="Promo kód"
                            values={data}
                            setValues={setData}
                            error={errors.code}
                        />
                        <div className="">
                            <input type="radio" onChange={(el) => {
                                setData({
                                    ...data,
                                    type: el.target.value
                                })
                            }} id="fixed" name="type" value="fixed" />
                            <label className='ml-2' htmlFor="fixed">Fixní sazby</label><br />
                            <input type="radio" onChange={(el) => {
                                setData({
                                    ...data,
                                    type: el.target.value
                                })
                            }} id="variable" name="type" value="variable" />
                            <label className='ml-2' htmlFor="variable">Zvýšení úroku u variabilní úložky</label><br />
                        </div>
                        <div className="col-span-2">
                            {data.type == "fixed" ?
                        <div className="grid grid-cols-4 gap-x-4">
                            <Input
                            name="percentages_3m"
                            type="number"
                            label="Úroková sazba 3 m (%)"
                            values={data}
                            setValues={setData}
                            error={errors.code}
                        />
                         <Input
                            name="percentages_6m"
                            type="number"
                            label="Úroková sazba 6 m (%)"
                            values={data}
                            setValues={setData}
                            error={errors.code}
                        />
                         <Input
                            name="percentages_12m"
                            type="number"
                            label="Úroková sazba 12 m (%)"
                            values={data}
                            setValues={setData}
                            error={errors.code}
                        />
                         <Input
                            name="percentages_24m"
                            type="number"
                            label="Úroková sazba 24 m (%)"
                            values={data}
                            setValues={setData}
                            error={errors.code}
                        />
                        </div>    
                        :
                        <Input
                            name="percentages_variable"
                            type="number"
                            label="Úrokové zvýhodnění (%)"
                            values={data}
                            setValues={setData}
                            error={errors.code}
                        />
                        }
                        </div>
                        
                        <Input
                            name="valid_to"
                            type="datetime-local"
                            label="Platné do"
                            values={data}
                            setValues={setData}
                            error={errors.code}
                        />
                        <div className="">
                            <input type="radio" id="once" onChange={(el) => {
                                setData({
                                    ...data,
                                    use_once: true
                                })}} name="only_once" value="once" />
                            <label className='ml-2' htmlFor="once">Lze použít pouze jednou</label><br />
                            <input onChange={(el) => {
                                setData({
                                    ...data,
                                    use_once: false
                                })}}
                                type="radio" id="multiple" name="only_once" value="multiple" />
                            <label className='ml-2' htmlFor="multiple">Může být použit vícekrát</label><br />
                        </div>
                    </form>
                </div>
            </div>
        </AdminLayout>
    );
}


function State({ code, previous }) {

    const now = new Date()

    if (getDate(code.from) > now) {
        return (
            <div className="bg-blue-400 bg-opacity-50 text-white font-semibold text-sm text-center px-4 rounded-full">Budoucí</div>
        )
    }

    else if ((getDate(code.from) < now) && (getDate(previous?.from) > now)) {
        return (
            <div className="bg-green text-white font-semibold text-sm rounded-full text-center px-4">Aktivní sazba</div>
        )
    }

    else if (getDate(code.from) < now) {
        return (
            <div className=" bg-white bg-opacity-30 text-white font-semibold text-sm rounded-full text-center px-4">Minulá sazba</div>
        )
    }

    else return (
        <div className=""></div>
    )
}